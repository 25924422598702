import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import Layout from '../../components/Layout'

import './style.css';


const Memories = () => {

  return (
    <Layout section="memories" pageTitle="Memories">
        <StaticImage className="container" src="../../images/memories/01.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/02.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/03.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/04.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/06.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/07.jpg"  layout="constrained" width={1200}  height={1200} />            
        <StaticImage className="container" src="../../images/memories/08.jpg"  layout="constrained" width={1200}  height={1200} />                        
        <StaticImage className="container" src="../../images/memories/09.jpg"  layout="constrained" width={1200}  height={1200} />            
        <StaticImage className="container" src="../../images/memories/10.jpg"  layout="constrained" width={1200}  height={1200} />                                    
        <StaticImage className="container" src="../../images/memories/11.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/12.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/13.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/14.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/15.jpg"  layout="constrained" width={1200}  height={1200} />
        <StaticImage className="container" src="../../images/memories/16.jpg"  layout="constrained" width={1200}  height={1200} />
    </Layout>
  )
}

export default Memories